import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getUser } from "../utils/constants";
import { fetchDataFromAPI } from "../utils/api";
const AddCompany = ({ onClose, show, data }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (data) {
      //   setLoading(true);
      formik.setFieldValue("company", data?.company_name);

      //   setLoading(false);
    }
  }, [data]);

  const validationSchema = Yup.object().shape({
    company: Yup.string().required("Please enter company name"),
  });

  const formik = useFormik({
    initialValues: {
      company: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const body = {
        company_name: values.company?.trimEnd(),
      };

      const user = JSON.parse(getUser());

      setLoading(true);
      if (data) {
        fetchDataFromAPI(
          `company/updateCompany?company_id=${data?.company_id}`,
          "put",
          body,
          user?.authToken
        )
          .then((res) => {
            toast.success(res?.message + " ✅", {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-success text-white",
            });

            onClose();
            formik.resetForm();
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-danger text-white",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        fetchDataFromAPI("company/addCompany", "post", body, user?.authToken)
          .then((res) => {
            toast.success(res?.message + " ✅", {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-success text-white",
            });

            onClose();
            formik.resetForm();
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-danger text-white",
            });
            console.log("add Admin error: ", error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  return (
    <Modal
      show={show}
      onHide={() => {
        onClose();
        formik.resetForm();
      }}
      centered
    >
      <Modal.Header closeButton>
        {data ? (
          <Modal.Title>Edit Company</Modal.Title>
        ) : (
          <Modal.Title>Add Company</Modal.Title>
        )}
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex flex-column ">
          <label className="form-label">Company</label>
          <input
            type="text"
            name="company"
            placeholder="Enter company name"
            className="form form-control bg-transparent text-black add_inputs"
            value={formik.values.company}
            onChange={(e) => {
              const formattedText = e.target.value.replace(/\./g, " "); // Remove all '.' characters
              formik.setFieldValue("company", formattedText.trimStart());
            }}
          />
          {formik.touched.company && formik.errors.company && (
            <div className="text-danger">{formik.errors.company}</div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          className="btn btn-primary"
          //   data-bs-dismiss="modal"
          onClick={() => {
            formik.resetForm();
            onClose();
          }}
        >
          Close
        </Button>
        <Button type="button" onClick={formik.handleSubmit} variant="danger">
          {!loading ? (
            <span className="indicator-label">{data ? "Update" : "Add"}</span>
          ) : (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCompany;
