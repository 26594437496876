import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getUser } from "../../utils/constants";
import { fetchDataFromAPI } from "../../utils/api";
import { toast } from "react-toastify";
import Processing from "../../components/Processing/Processing";

const EditAgreement = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = useState("");

  const [editorHtml, setEditorHtml] = useState("");
  const admin = JSON.parse(getUser());

  const handleEditorChange = (html) => {
    formik.setFieldValue("content", html);
  };

  useEffect(() => {
    setLoading(true);
    getAgreement();
  }, []);

  const getAgreement = async () => {
    setLoading(true);
    const user = JSON.parse(getUser());
    try {
      const response = await fetchDataFromAPI(
        "agreement/getAgreements",
        "get",
        "",
        user?.authToken
      );

      setAgreement(response?.data);
      setLoading(false);
      formik.setValues({
        versionnumber: response?.data?.version || "",
        title: response?.data?.title || "",
        content: response?.data?.content || "",
      });
    } catch (error) {
      setLoading(false);

      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   setLoading(true);
  //   getAgreementData();
  // }, []);

  const getAgreementData = () => {
    setLoading(true);
    formik.setFieldValue("versionnumber", agreement?.version);
    formik.setFieldValue("title", agreement?.title);
    formik.setFieldValue("content", agreement?.Content);
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    versionnumber: Yup.string().required("Please enter version number"),
    title: Yup.string().required("Please enter title"),
    content: Yup.string().required("Please enter content"),
  });

  const formik = useFormik({
    initialValues: {
      versionnumber: "",
      title: "",
      content: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { versionnumber, title, content } = values;
      const admin = JSON.parse(getUser());

      setLoading(true);

      const body = {
        version: values?.versionnumber,
        id: agreement?.agreement_id,
        admin_id: admin?.user_id,
        title: values?.title,
        content: values?.content,
      };

      fetchDataFromAPI("admin/updateAgreement", "put", body, admin?.authToken)
        .then((res) => {
          toast.success("agreement updated successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });

          setLoading(false);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          setLoading(false);

          console.log("error", error);
        });
    },
  });

  return (
    <div className="main" id="main">
      {loading && <Processing />}

      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/agreement"> Driver Agreement</Link>
            </li>
            <li className="breadcrumb-item active">Edit Driver Agreement</li>
          </ol>
        </nav>
      </div>
      <div className="card">
        <div className="row mb-3" />

        <form onSubmit={formik.handleSubmit}>
          <div className="row mb-3 mx-2">
            <div className="col-md-6">
              <label htmlFor="versionNumber" className="form-label">
                Version Number
              </label>
              <input
                name="versionNumber"
                type="text"
                id="versionNumber"
                className={`form-control ${
                  formik.touched.versionnumber && formik.errors.versionnumber
                    ? "is-invalid"
                    : ""
                }`}
                value={formik.values.versionnumber}
                onChange={(e) => {
                  formik.setFieldValue(
                    "versionnumber",
                    e.target.value?.trimStart()
                  );
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.versionnumber && formik.errors.versionnumber && (
                <div className="invalid-feedback">
                  {formik.errors.versionnumber}
                </div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                name="title"
                type="text"
                id="title"
                className={`form-control ${
                  formik.touched.title && formik.errors.title
                    ? "is-invalid"
                    : ""
                }`}
                value={formik.values.title}
                onChange={(e) => {
                  formik.setFieldValue("title", e.target.value?.trimStart());
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.title && formik.errors.title && (
                <div className="invalid-feedback">{formik.errors.title}</div>
              )}
            </div>
            <div className="row mb-3" />
            <div className="">
              <ReactQuill
                theme="snow"
                value={formik.values.content}
                onChange={handleEditorChange}
                style={{ height: 200 }}
                // onBlur={formik.handleBlur}

                // style={{height: 200}}
              />
            </div>
            <div className="row mb-3" />
            {/* <div className="">
           
              <label htmlFor="content" className="form-label">
                Content
              </label>
              <textarea
                name="content"
                id="content"
                className={`form-control ${
                  formik.touched.content && formik.errors.content
                    ? 'is-invalid'
                    : ''
                }`}
                value={formik.values.content}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{height: 200}}
              />
              {formik.touched.content && formik.errors.content && (
                <div className="invalid-feedback">{formik.errors.content}</div>
              )}
            </div> */}
          </div>
          <div className="row mb-5" />

          <div className="text-center my-4">
            <button type="submit" className="btn btn-warning btn">
              {!loading && (
                <span className="indicator-label">Save Updates</span>
              )}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
          <div className="row mb-2" />
        </form>
      </div>
    </div>
  );
};

export default EditAgreement;
