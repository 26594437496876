import React, { useEffect, useState } from "react";
import { Button, Modal, Pagination } from "react-bootstrap";
import Processing from "../../components/Processing/Processing";
import { Link, useNavigate } from "react-router-dom";
import { fetchDataFromAPI } from "../../utils/api";
import { getUser } from "../../utils/constants";
import { toast } from "react-toastify";
import moment from "moment";
import AddCompany from "../../common/AddCompany";
const Company = () => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState([]);
  const user = JSON.parse(getUser());

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1); // Initialize total pages
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDriverId, setSelectedDriverId] = useState(null);

  const [showCompany, setShowCompany] = useState(false);

  const showDeleteConfirmation = (id) => {
    setSelectedDriverId(id);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    getCompany(currentPage);
  }, [currentPage]);

  const getCompany = (page) => {
    setLoading(true);
    fetchDataFromAPI(
      `company/getCompany?page=${page}?limit=10`,
      "get",
      "",
      user?.authToken
    )
      .then((res) => {
        setCompany(res.data);
        setTotalPages(res?.pagination?.totalPages);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    getCompany(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getCompany(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getCompany(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button"
          >
            {i}
          </Pagination.Item>
        );
      } else if (paginationItems[paginationItems.length - 1] !== "...") {
        paginationItems.push("...");
      }
    }

    return paginationItems;
  };

  const deleteUsers = async () => {
    try {
      fetchDataFromAPI(
        `company/deleteCompany?company_id=${selectedDriverId}`,
        "delete",
        "",
        user?.authToken
      )
        .then(() => {
          toast.success("Company delete successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          setShowDeleteModal(false);
          setCurrentPage(1);
          getCompany();
        })
        .catch((error) => {
          setShowDeleteModal(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
        });
      //   const response = await axios.delete(`${base_url}admin/deleteUser`, {
      //     params: params,
      //     headers: {
      //       Accept: 'application/json',
      //       'Content-Type': 'application/json',
      //       Authorization: `Bearer ${user?.authToken}`,
      //     },
      //   });
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        className: "bg-danger text-white",
      });
      console.log("error", error);
    }
  };

  const btnCompany = (id) => {
    setShowCompany(true);
    setSelectedDriverId(id);
  };

  const onClose = () => {
    setShowCompany(false);
    setCurrentPage(1);
    getCompany();
  };

  return (
    <>
      <div id="main" className="main">
        {loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/settings">Settings</Link>
              </li>
              <li className="breadcrumb-item active">Company</li>
            </ol>
          </nav>
        </div>
        <div className="card px-3">
          <div className="d-flex align-items-center justify-content-lg-between btn-View mt-2 ">
            <div>
              <h3 className="m-0 font-weight-bold ">Company List</h3>
            </div>

            {user?.type === "super_admin" && (
              <div className="text-center ">
                <button
                  type="submit"
                  className="btn btn-warning w-100"
                  onClick={() => {
                    setSelectedDriverId(null);
                    setShowCompany(true);
                  }}
                >
                  Add Company
                </button>
              </div>
            )}
          </div>
          <hr className="mt-2" />

          {/* <!-- Default Table --> */}
          <div className="table-responsive">
            <table className=" table text-nowrap align-items-end table-hover">
              <thead>
                <tr>
                  {/* <th scope="col">Id</th> */}
                  <th scope="col" role="button">
                    Company Name
                  </th>

                  <th scope="col" className="text-end ">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {company?.map((item, index) => {
                  return (
                    <tr className="">
                      {/* <td scope="row">{item?.company_id}</td> */}
                      <td>{item?.company_name}</td>

                      <td className="d-flex justify-content-end pe-4 gap-2">
                        {/* <div role="button" onClick={() => btnCompany(item)}>
                          <i class="bi bi-pencil-square"></i>
                        </div> */}
                        {user?.type === "super_admin" && (
                          <div
                            role="button"
                            onClick={() =>
                              showDeleteConfirmation(item?.company_id)
                            }
                            className=""
                          >
                            <i className="bi bi-trash"></i>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Modal
            show={showDeleteModal}
            centered
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <p className="m-0">
                Are you sure you'd like to delete this company?
              </p>
            </Modal.Body>
            <Modal.Footer className="text-end gap-1 border-0 px-3 py-2">
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
              <Button variant="danger" onClick={() => deleteUsers()}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Pagination */}
          {totalPages > 1 && ( // Check if there's more than one page
            <Pagination className="align-items-center justify-content-center">
              {currentPage !== 1 && (
                <p
                  className="text-center mb-sm-0 pe-3 cursor-pointer"
                  onClick={handlePrevious}
                  role="button"
                >
                  Previous
                </p>
              )}
              {getPaginationItems()}
              {currentPage !== totalPages && company?.length > 0 && (
                <p
                  className="text-center mb-sm-0 ps-3 cursor-pointer"
                  onClick={handleNext}
                  role="button"
                >
                  Next
                </p>
              )}
            </Pagination>
          )}
          {totalPages <= 1 && company?.length === 0 && (
            <p className="text-center">No data available</p>
          )}

          {/* <!-- End Default Table Example --> */}
        </div>
      </div>

      <AddCompany
        show={showCompany}
        data={selectedDriverId}
        onClose={onClose}
      />
    </>
  );
};

export default Company;
