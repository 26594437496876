import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { base_url, fetchDataFromAPI } from "../../utils/api";
import constants, { getUser } from "../../utils/constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import Processing from "../../components/Processing/Processing";
import { Pagination, Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
const Users = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const [errorShown, setErrorShown] = useState(false);

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Assuming 10 items per page based on your structure
  const [totalPages, setTotalPages] = useState(1); // Initialize total pages

  const [sortByFirstName, setSortByFirstName] = useState("asc");
  const [sortByLastName, setSortByLastName] = useState("asc");
  const [isDone, setIsDone] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDriverId, setSelectedDriverId] = useState(null);

  useEffect(() => {
    getUsers(currentPage);
  }, []);
  const user = JSON.parse(getUser());

  const showDeleteConfirmation = (id) => {
    setSelectedDriverId(id);
    setShowDeleteModal(true);
  };

  // get users funcation
  const getUsers = async (pageNumber) => {
    setLoading(true);
    const user = JSON.parse(getUser());
    // fetchDataFromAPI(
    //   `admin/admins?device_type=&page=${pageNumber}&id=${user?._id}`,
    //   'get',
    //   '',
    //   user?.authToken,
    // )
    const params = {
      id: user?.user_id,
      // device_type: 'Android',
      uid: formik.values.id,
      sortOrder: "DESC",
      page: pageNumber,
      firstName: formik.values.firstname,
      lastName: formik.values.lastname,
      email: formik.values.email,
      mobile: formik.values.mobile,
      spsv: formik.values.spsv,
    };

    axios({
      url: `${base_url}admin/admins`,
      params: params,
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "true",

        Authorization: `Bearer ${user?.authToken}`,
      },
    })
      .then((response) => {
        setUsers(response?.data?.data);
        setTotalPages(response?.data?.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error is: ", error?.response?.data);
        if (error?.response?.data?.status === 500) {
          localStorage.removeItem(constants.USER);
          navigate("/login");
          if (!isDone) {
            toast.error("Internal server error", {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-danger text-white",
            });
            setIsDone(true);
          }
        } else {
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          console.log("users error :", error);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      id: "",
      firstname: "",
      lastname: "",
      mobile: "",
      email: "",
      spsv: "",
    },
    validationSchema: Yup.object({
      id: Yup.string().notRequired(),
      firstname: Yup.string().notRequired(),
      lastname: Yup.string().notRequired(),
      mobile: Yup.string().notRequired(),
      email: Yup.string().notRequired(),
      spsv: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      const { firstname, lastname, mobile, email, spsv } = values;
      const user = JSON.parse(getUser());
      getUsers(1);
    },
  });

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    getUsers(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getUsers(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getUsers(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button"
          >
            {i}
          </Pagination.Item>
        );
      } else if (paginationItems[paginationItems.length - 1] !== "...") {
        paginationItems.push("...");
      }
    }

    return paginationItems;
  };

  // Function to handle sorting by First Name
  const handleSortByFirstName = () => {
    const order = sortByFirstName === "asc" ? "desc" : "asc";
    setSortByFirstName(order);
    setSortByLastName("asc"); // Reset sorting for other columns if needed

    // Perform sorting based on First Name
    const sortedDrivers = [...users].sort((a, b) => {
      if (order === "asc") {
        return a.first_name.localeCompare(b.first_name);
      } else {
        return b.first_name.localeCompare(a.first_name);
      }
    });

    // Update the state with the sorted data
    setUsers(sortedDrivers);
  };

  // Function to handle sorting by Last Name
  const handleSortByLastName = () => {
    const order = sortByLastName === "asc" ? "desc" : "asc";
    setSortByLastName(order);
    setSortByFirstName("asc"); // Reset sorting for other columns if needed

    // Perform sorting based on Last Name
    const sortedDrivers = [...users].sort((a, b) => {
      if (order === "asc") {
        return a.last_name.localeCompare(b.last_name);
      } else {
        return b.last_name.localeCompare(a.last_name);
      }
    });

    // Update the state with the sorted data
    setUsers(sortedDrivers);
  };

  const deleteUsers = async () => {
    const user = JSON.parse(getUser());
    const params = {
      id: selectedDriverId,
    };
    try {
      const response = await axios.delete(`${base_url}admin/deleteUser`, {
        params: params,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.authToken}`,
        },
      });
      toast.success("User delete successfully ✅", {
        position: toast.POSITION.TOP_RIGHT,
        className: "bg-success text-white",
      });
      setShowDeleteModal(false);
      setCurrentPage(1);
      getUsers();
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        className: "bg-danger text-white",
      });
      console.log("error", error);
    }
  };
  return (
    <div id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Users</li>
          </ol>
        </nav>
      </div>
      <div className="card">
        <form className="mx-3" onSubmit={formik.handleSubmit}>
          <div className="row mb-3"></div>
          <div className="row mb-3 align-items-end">
            <div className="col-md-2">
              <label htmlFor="id" className="form-label ">
                ID
              </label>
              <input
                name="id"
                type="text"
                className="form-control"
                onChange={(e) => {
                  formik.setFieldValue("id", e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                name="firstName"
                type="text"
                className="form-control"
                id="firstName"
                onChange={(e) => {
                  formik.setFieldValue(
                    "firstname",
                    e.target.value?.trimStart()
                  );
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                name="lastName"
                type="text"
                className="form-control"
                id="lastName"
                onChange={(e) => {
                  formik.setFieldValue("lastname", e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="Mobile" className="form-label">
                Mobile
              </label>
              <input
                name="Mobile"
                type="text"
                className="form-control"
                id="Mobile"
                onChange={(e) => {
                  formik.setFieldValue("mobile", e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="Email" className="form-label">
                Email
              </label>
              <input
                name="Email"
                type="text"
                className="form-control"
                id="Email"
                onChange={(e) => {
                  formik.setFieldValue("email", e.target.value?.trimStart());
                }}
              />
            </div>

            <div className="col-md-2 text-center ">
              <button
                type="submit"
                className="btn btn-warning w-100 btn-serach"
              >
                Search
              </button>
            </div>
            <div className="col-md-1">
              {/* {filteredDrivers.length == 0 ? (
                console.log('no data found csv')
              ) : ( */}
              {/* <CSVLink data={drivers} headers={headers} filename={'users.csv'}>
                <i className="bi bi-share"></i>
              </CSVLink> */}
              {/* )} */}

              {/* <i className="bi bi-share"></i> */}
            </div>
          </div>
        </form>
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-lg-between btn-View">
            <div>
              <h3 className="m-0 font-weight-bold">Users List</h3>
            </div>

            {user?.type === "super_admin" && (
              <div className=" text-center ">
                <button
                  type="submit"
                  className="btn btn-warning w-100"
                  onClick={() => navigate("/adduser")}
                >
                  Add Users
                </button>
              </div>
            )}
          </div>
          <hr />
          {/* <!-- Default Table --> */}
          <div className="table-responsive">
            <table className="table text-nowrap align-items-end table-hover">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th
                    scope="col "
                    role="button"
                    onClick={handleSortByFirstName}
                  >
                    First Name {sortByFirstName === "asc" ? "▲" : "▼"}
                  </th>

                  <th scope="col" role="button" onClick={handleSortByLastName}>
                    Last Name {sortByLastName === "asc" ? "▲" : "▼"}
                  </th>

                  <th scope="col">Email</th>
                  <th scope="col" className="text-center">
                    Last Login
                  </th>
                  <th scope="col">Registration Date</th>
                  <th scope="col">Role</th>
                  <th scope="col" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item, index) => {
                  const formattedDate = moment(item?.createdAt).format(
                    "DD-MM-YYYY"
                  ); // Adjust the format as needed

                  const lastLogin = item?.last_login
                    ? moment(item.last_login).format("DD-MM-YYYY")
                    : "-";

                  return (
                    <tr className="">
                      <td scope="row">{item?.user_id}</td>
                      <td>{item?.first_name}</td>
                      <td>{item?.last_name}</td>

                      <td>{item?.email}</td>
                      {/* <td>{item?.lastLogin}</td> */}
                      <td className="text-center">{lastLogin}</td>
                      <td>{formattedDate}</td>
                      {item?.type === "super_admin" ? (
                        <td>Super Admin</td>
                      ) : (
                        <td>Admin</td>
                      )}
                      <td className="d-flex justify-content-center gap-2">
                        <div
                          role="button"
                          onClick={() =>
                            navigate(`/users/${item?.user_id}`, {
                              state: { user: item },
                            })
                          }
                        >
                          <i class="bi bi-pencil-square"></i>
                        </div>
                        {user?.type === "super_admin" && (
                          <div
                            role="button"
                            onClick={() =>
                              showDeleteConfirmation(item?.user_id)
                            }
                            className=""
                          >
                            <i className="bi bi-trash"></i>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* <!-- End Default Table Example --> */}
        </div>

        <Modal
          show={showDeleteModal}
          centered
          onHide={() => setShowDeleteModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <p className="m-0">Are you sure you'd like to delete this user?</p>
          </Modal.Body>
          <Modal.Footer className="text-end gap-1 border-0 px-3 py-2">
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={() => deleteUsers()}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Pagination */}
        {totalPages > 1 && ( // Check if there's more than one page
          <Pagination className="align-items-center justify-content-center">
            {currentPage !== 1 && (
              <p
                className="text-center mb-sm-0 pe-3 cursor-pointer"
                onClick={handlePrevious}
                role="button"
              >
                Previous
              </p>
            )}
            {getPaginationItems()}
            {currentPage !== totalPages && users.length > 0 && (
              <p
                className="text-center mb-sm-0 ps-3 cursor-pointer"
                onClick={handleNext}
                role="button"
              >
                Next
              </p>
            )}
          </Pagination>
        )}
        {totalPages <= 1 && users?.length === 0 && (
          <p className="text-center">No data available</p>
        )}
      </div>
    </div>
  );
};

export default Users;
