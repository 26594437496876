import AddUser from "../pages/AddUser/AddUser";
import Company from "../pages/Company/Company";
import Dashboard from "../pages/Dashboard/Dashboard";
import Driver from "../pages/Driver/Driver";
import DriverAgrement from "../pages/DriverAgrement/DriverAgrement";
import DriverEdit from "../pages/DriverEdit/DriverEdit";
import EditAgreement from "../pages/EditAgreement/EditAgreement";
import EditProfile from "../pages/EditProfile/EditProfile";
import FormNewPass from "../pages/FormNewPass/FormNewPass";
import ManageReports from "../pages/ManageReports/ManageReports";
import Reports from "../pages/Reports/Reports";
import Settings from "../pages/Settings/Settings";
import Users from "../pages/Users/Users";
import UsersEdit from "../pages/UsersEdit/UsersEdit";
import ViewUserDetail from "../pages/ViewUserDetail/ViewUserDetail";

import PrivateRoute from "./RouteProtection";

const routes = [
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
  },
  {
    path: "/driver",
    element: (
      <PrivateRoute>
        <Driver />
      </PrivateRoute>
    ),
  },
  {
    path: "/driver/edit/:id",
    element: (
      <PrivateRoute>
        <DriverEdit />
      </PrivateRoute>
    ),
  },
  {
    path: "/driver/view/:id",
    element: (
      <PrivateRoute>
        <ViewUserDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/agreement",
    element: (
      <PrivateRoute>
        <DriverAgrement />
      </PrivateRoute>
    ),
  },
  {
    path: "/reports",
    element: (
      <PrivateRoute>
        <Reports />
      </PrivateRoute>
    ),
  },
  {
    path: "/managereports",
    element: (
      <PrivateRoute>
        <ManageReports />
      </PrivateRoute>
    ),
  },
  {
    path: "/users",
    element: (
      <PrivateRoute>
        <Users />
      </PrivateRoute>
    ),
  },
  {
    path: "/users/:id",
    element: (
      <PrivateRoute>
        <UsersEdit />
      </PrivateRoute>
    ),
  },
  {
    path: "/adduser",
    element: (
      <PrivateRoute>
        <AddUser />
      </PrivateRoute>
    ),
  },
  {
    path: "/editprofile",
    element: (
      <PrivateRoute>
        <EditProfile />
      </PrivateRoute>
    ),
  },
  {
    path: "/editagreement",
    element: (
      <PrivateRoute>
        <EditAgreement />
      </PrivateRoute>
    ),
  },
  {
    path: "/settings",
    element: (
      <PrivateRoute>
        <Settings />
      </PrivateRoute>
    ),
  },

  {
    path: "/company",
    element: (
      <PrivateRoute>
        <Company />
      </PrivateRoute>
    ),
  },
];

export default routes;
